import moment, { utc } from "moment";

export function findStartFinancialYearDate(properties = []) {
  const oldestDate = properties.reduce((prevOldDate, property) => {
    const purchaseDate = new Date(property.purchases.purchaseDate);
    if (!prevOldDate || purchaseDate < prevOldDate) {
      prevOldDate = purchaseDate;
    }
    return prevOldDate;
  }, null);
  return oldestDate;
}

// Function to filter financial years based on start date (and optional end date)

export function filterFinancialYears(
  FINANCIAL_YEARS,
  startDate,
  endDate = null
) {
  // Extract month and year from the start date
  const startMonth = startDate.getMonth() + 1; // Adjust month to be 1-indexed (0-indexed by default)
  const startYear = startDate.getFullYear();

  // Find the index of the financial year that includes the start date
  const startIndex = FINANCIAL_YEARS.findIndex((year) => {
    // Extract start year, start month, and end year from the financial year
    const yearStart = new Date(year.startDate).getFullYear();
    const yearStartMonth = new Date(year.startDate).getMonth() + 1;
    const yearEnd = new Date(year.endDate).getFullYear();
    const yearEndMonth = new Date(year.endDate).getMonth() + 1;

    // Check if the start date falls within the financial year
    return (
      (startYear > yearStart && startYear < yearEnd) ||
      (startYear === yearStart && startMonth >= yearStartMonth) ||
      (startYear === yearEnd && startMonth <= 6) // Assuming FY ends in June
    );
  });

  // If the start date does not fall within any financial year, return an empty array
  if (startIndex === -1) return [];

  // If no end date is provided, return financial years from the start index onwards
  if (!endDate) return FINANCIAL_YEARS.slice(startIndex);

  // Extract month and year from the end date
  const endMonth = endDate.getMonth() + 1; // Adjust month to be 1-indexed (0-indexed by default)
  const endYear = endDate.getFullYear();

  // Find the index of the financial year that includes the end date
  const endIndex = FINANCIAL_YEARS.findIndex((year) => {
    // Extract start year, start month, and end year from the financial year
    const yearStart = new Date(year.startDate).getFullYear();
    const yearStartMonth = new Date(year.startDate).getMonth() + 1;
    const yearEnd = new Date(year.endDate).getFullYear();
    const yearEndMonth = new Date(year.endDate).getMonth() + 1;

    // Check if the end date falls within the financial year
    return (
      (endYear > yearStart && endYear < yearEnd) ||
      (endYear === yearStart && endMonth >= yearStartMonth) ||
      (endYear === yearEnd && endMonth <= yearEndMonth)
    );
  });

  // Return financial years from start index to end index inclusive
  return FINANCIAL_YEARS.slice(startIndex, endIndex + 1);
}

export function utcStartDate(date) {
  return moment(date)
    .startOf("day")
    .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
}

export function utcEndDate(date) {
  return moment(date)
    .endOf("day")
    .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
}
