import React from "react";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import SmsIcon from "@mui/icons-material/Sms";
import Flag1 from "../../../assests/flag1.png";
import Flag2 from "../../../assests/flag2.png";

import {
  Box,
  Container,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Gplay from "../../../assests/googleplaybor.png";
import Astore from "../../../assests/appstorebor.png";

const HomeFooter = () => {
  const navigate = useNavigate();
  function scrollToTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  return (
    <Grid item xs={12}>
      <Box
        py={4}
        width={"100%"}
        sx={{
          bgcolor: "#24003D",
          // height: { xs: "100%", md: "100%" },
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Container>
          <Grid
            container
            spacing={2}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Grid item xs={6} md={2}>
              <Stack spacing={1}>
                <Typography
                  onClick={() => navigate("/about")}
                  sx={{
                    fontSize: { xs: "17px" },
                    fontWeight: "500",
                    lineHeight: 1.5,
                    color: "#fff",
                    cursor: "pointer",
                  }}
                >
                  About Us
                </Typography>
                <Typography
                  onClick={() => navigate("/about")}
                  sx={{
                    fontSize: { xs: "14px" },
                    lineHeight: 1.5,
                    color: "#fff",
                    fontWeight: 400,
                    cursor: "pointer",
                  }}
                >
                  Our Story
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={6} md={2}>
              <Stack spacing={1}>
                <Typography
                  onClick={() => navigate("/features")}
                  sx={{
                    fontSize: { xs: "17px" },
                    fontWeight: "500",

                    lineHeight: 1.5,
                    color: "#fff",
                    cursor: "pointer",
                  }}
                >
                  Features
                </Typography>
                <Typography
                  onClick={() => navigate("/features")}
                  sx={{
                    fontSize: { xs: "14px" },

                    lineHeight: 1.5,
                    color: "#fff",
                    fontWeight: 400,
                    cursor: "pointer",
                  }}
                >
                  Rental Accounting
                </Typography>
                <Typography
                  onClick={() => navigate("/features")}
                  sx={{
                    fontSize: { xs: "14px" },
                    color: "#fff",
                    fontWeight: 400,
                    lineHeight: 1.5,
                    cursor: "pointer",
                  }}
                >
                  Dashboards
                </Typography>
                <Typography
                  onClick={() => navigate("/features")}
                  sx={{
                    fontSize: { xs: "14px" },
                    color: "#fff",
                    fontWeight: 400,
                    lineHeight: 1.5,
                    cursor: "pointer",
                  }}
                >
                  Reporting
                </Typography>
                <Typography
                  onClick={() => navigate("/features")}
                  sx={{
                    fontSize: { xs: "14px" },
                    color: "#fff",
                    fontWeight: 400,
                    lineHeight: 1.5,
                    cursor: "pointer",
                  }}
                >
                  Digital Storage
                </Typography>
                <Typography
                  onClick={() => navigate("/features")}
                  sx={{
                    fontSize: { xs: "14px" },
                    color: "#fff",
                    fontWeight: 400,
                    lineHeight: 1.5,
                    cursor: "pointer",
                  }}
                >
                  Marketplace
                </Typography>
                <Typography
                  onClick={() => navigate("/features")}
                  sx={{
                    fontSize: { xs: "14px" },
                    color: "#fff",
                    fontWeight: 400,
                    lineHeight: 1.5,
                    cursor: "pointer",
                  }}
                >
                  What if Analysis
                </Typography>

                <Typography
                  onClick={() => navigate("/features")}
                  sx={{
                    fontSize: { xs: "14px" },
                    color: "#fff",
                    fontWeight: 400,
                    lineHeight: 1.5,
                    cursor: "pointer",
                  }}
                >
                  Request a Feature
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={6} md={2}>
              <Stack spacing={1}>
                <Typography
                  onClick={() => navigate("/pricing")}
                  sx={{
                    fontSize: { xs: "17px" },
                    fontWeight: "500",

                    lineHeight: 1.5,
                    color: "#fff",
                    cursor: "pointer",
                  }}
                >
                  Pricing
                </Typography>
                <Typography
                  onClick={() => navigate("/pricing")}
                  sx={{
                    fontSize: { xs: "14px" },
                    color: "#fff",
                    fontWeight: 400,
                    lineHeight: 1.5,
                    cursor: "pointer",
                  }}
                >
                  Property Investor
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={6} md={2}>
              <Stack spacing={1}>
                <Typography
                  // onClick={() => navigate("/resources")}
                  sx={{
                    fontSize: { xs: "17px" },
                    fontWeight: "500",

                    lineHeight: 1.5,
                    color: "#fff",
                    cursor: "pointer",
                  }}
                >
                  Resources
                </Typography>

                <Typography
                  onClick={() => navigate("/faq-s")}
                  sx={{
                    fontSize: { xs: "14px" },
                    color: "#fff",
                    fontWeight: 400,
                    lineHeight: 1.5,
                    cursor: "pointer",
                  }}
                >
                  FAQ's
                </Typography>
                <Typography
                  onClick={() => navigate("/calculators")}
                  sx={{
                    fontSize: { xs: "14px" },
                    color: "#fff",
                    fontWeight: 400,
                    lineHeight: 1.5,
                    cursor: "pointer",
                  }}
                >
                  Calculators
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={6} md={2}>
              <Stack spacing={1}>
                <Typography
                  onClick={() => navigate("/contact")}
                  sx={{
                    fontSize: { xs: "17px" },
                    fontWeight: "500",

                    lineHeight: 1.5,
                    color: "#fff",
                    cursor: "pointer",
                  }}
                >
                  Contact Us
                </Typography>
              </Stack>
            </Grid>
            {/* <Grid container>
              <Grid item xs={12}>
                <Stack
                  direction={"row"}
                  spacing={1}
                  sx={{
                    display: "flex",
                    justifyContent: { md: "flex-end", xs: "center" },
                    alignItems: "center",
                    marginTop: { md: 0, xs: 3 },
                  }}
                >
                  <Box
                    sx={{
                      border: "1px solid #fff",
                      padding: 0.3,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: "3px",
                    }}
                  >
                    <TwitterIcon
                      sx={{
                        color: "#fff",
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      border: "1px solid #fff",
                      padding: 0.3,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: "3px",
                    }}
                  >
                    <LinkedInIcon
                      sx={{
                        color: "#fff",
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      border: "1px solid #fff",
                      padding: 0.3,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: "3px",
                    }}
                  >
                    <FacebookIcon
                      sx={{
                        color: "#fff",
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      border: "1px solid #fff",
                      padding: 0.3,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: "3px",
                    }}
                  >
                    <YouTubeIcon
                      sx={{
                        color: "#fff",
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      border: "1px solid #fff",

                      padding: 0.3,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: "3px",
                    }}
                  >
                    <SmsIcon
                      sx={{
                        color: "#fff",
                      }}
                    />
                  </Box>
                </Stack>
              </Grid>
            </Grid> */}

            <Grid
              container
              spacing={2}
              mt={2}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={12}>
                <Grid
                  container
                  spacing={3}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Grid item xs={12} md={6}>
                    <Stack spacing={1}>
                      <Stack
                        direction={"row"}
                        justifyContent={{
                          md: "flex-start",
                          xs: "center",
                        }}
                        spacing={2}
                      >
                        <a
                          href="https://play.google.com/store/apps/details?id=com.investpulse.app&hl=en"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img src={Gplay} alt="Google Play" width="150px" />
                        </a>
                        <a
                          href="https://apps.apple.com/us/app/investpulse/id6502841597"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img src={Astore} alt="Apple Store" width="150px" />
                        </a>
                      </Stack>
                      <Typography sx={{ color: "#fff", fontWeight: 100, fontSize:12 }}>
                        App Store and the Apple logo are trademarks of Apple
                        Inc.<br/> Google Play and the Google Play logo are trademarks
                        of Google LLC.
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Stack
                      direction={"row"}
                      spacing={1}
                      sx={{
                        display: "flex",
                        justifyContent: { md: "flex-end", xs: "center" },
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          border: "1px solid #fff",
                          padding: 0.3,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: "3px",
                        }}
                      >
                        <TwitterIcon
                          sx={{
                            color: "#fff",
                          }}
                        />
                      </Box>
                      <Box
                        sx={{
                          border: "1px solid #fff",
                          padding: 0.3,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: "3px",
                        }}
                      >
                        <LinkedInIcon
                          sx={{
                            color: "#fff",
                          }}
                        />
                      </Box>
                      <Box
                        sx={{
                          border: "1px solid #fff",
                          padding: 0.3,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: "3px",
                        }}
                      >
                        <FacebookIcon
                          sx={{
                            color: "#fff",
                          }}
                        />
                      </Box>
                      <Box
                        sx={{
                          border: "1px solid #fff",
                          padding: 0.3,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: "3px",
                        }}
                      >
                        <YouTubeIcon
                          sx={{
                            color: "#fff",
                          }}
                        />
                      </Box>
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6}>
                <Stack>
                  <Typography sx={{ color: "#fff", fontWeight: 100 }}>
                    {" "}
                    <span
                      style={{
                        boxShadow: 1,
                        // backgroundColor: "#fff",
                        padding: 0.2,
                        color: "#fff",
                      }}
                    >
                      Copyright ©
                    </span>{" "}
                    2024 InvestPulse ABN 68 613 057 097. All rights reserved.
                  </Typography>
                </Stack>
              </Grid>

              {/* Second Grid Item */}
              <Grid item xs={12} md={6}>
                <Stack
                  direction="row"
                  sx={{ boxShadow: 1, padding: 0.1 }}
                  justifyContent={{ md: "end", xs: "start" }}
                  px={2}
                >
                  <Typography
                    onClick={() => {
                      navigate("/cookie-policy");
                      scrollToTop(); // Scroll to top after navigating
                    }}
                    sx={{ fontWeight: 100, color: "#fff", cursor: "pointer" }}
                  >
                    Cookie Policy
                  </Typography>
                  <Divider
                    orientation="vertical"
                    flexItem
                    sx={{
                      borderWidth: 1,
                      backgroundColor: "#fff",
                      marginLeft: 1,
                      marginRight: 1,
                    }}
                  />
                  <Typography
                    onClick={() => {
                      navigate("/privacy");
                      scrollToTop(); // Scroll to top after navigating
                    }}
                    sx={{ fontWeight: 100, color: "#fff", cursor: "pointer" }}
                  >
                    Privacy Policy
                  </Typography>
                  <Divider
                    orientation="vertical"
                    flexItem
                    sx={{
                      borderWidth: 1,
                      backgroundColor: "#fff",
                      marginLeft: 1,
                      marginRight: 1,
                    }}
                  />
                  <Typography
                    onClick={() => {
                      navigate("/saasagreement");
                      scrollToTop(); // Scroll to top after navigating
                    }}
                    sx={{ fontWeight: 100, color: "#fff", cursor: "pointer" }}
                  >
                    SaaS Agreement
                  </Typography>
                  <Divider
                    orientation="vertical"
                    flexItem
                    sx={{
                      borderWidth: 1,
                      backgroundColor: "#fff",
                      marginLeft: 1,
                      marginRight: 1,
                    }}
                  />
                  <Typography
                    onClick={() => {
                      navigate("/terms-and-conditions");
                      scrollToTop(); // Scroll to top after navigating
                    }}
                    sx={{ fontWeight: 100, color: "#fff", cursor: "pointer" }}
                  >
                    Website T&C’s
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
            <Stack mt={2} flexDirection={"row"} alignItems={"center"}>
              <Box sx={{ display: "flex" }}>
                <img
                  src={Flag1}
                  style={{
                    width: "auto",
                    height: "25px",
                    paddingRight: "10px",
                  }}
                />
                <img src={Flag2} style={{ width: "auto", height: "25px" }} />
              </Box>
              <Typography
                px={2}
                sx={{
                  color: "#fff",
                  fontSize: "12px",
                  fontWeight: 400,
                  lineHeight: "15px",
                }}
              >
                InvestPulse acknowledges the Traditional Custodians of Country
                throughout Australia and their continuing connection to land,
                sea and community. We pay our respects to all Aboriginal and
                Torres Strait Islander peoples, their cultures and to their
                elders past, present and emerging.
              </Typography>
            </Stack>
          </Grid>
        </Container>
      </Box>
    </Grid>
  );
};

export default HomeFooter;
