import AccountCircle from "@mui/icons-material/AccountCircle";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import DashboardIcon from "@mui/icons-material/Dashboard";
import HelpIcon from "@mui/icons-material/Help";
import LogoutIcon from "@mui/icons-material/Logout";
import MapsHomeWorkIcon from "@mui/icons-material/MapsHomeWork";
import MoreIcon from "@mui/icons-material/MoreVert";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import CustomSwitch from "../../common/CustomSwitch";
import MenuIcon from "@mui/icons-material/Menu";
import { DocumentScanner } from "@mui/icons-material";
import KeyboardBackspaceIcon from "@mui/icons-material/ArrowBack";
import DonutSmallIcon from "@mui/icons-material/DonutSmall";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import {
  Avatar,
  Button,
  capitalize,
  FormControl,
  ListItem,
  ListItemButton,
  ListItemIcon,
  TextField,
  Tooltip,
} from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import List from "@mui/material/List";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { styled, useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
// import Logo from "../../assests/mainLogo.png";
// import Logo from "../../assests/logo/crop/white_logo_transparent.png";
// import Logo from "../../assests/logo/New-crop/white_transparent_logo.png";
import Logo from "../../assests/logo/New-crop/color_logo.png";


import CommonSelect from "../../common/CommonSelect";
import apiRequest, { cookies } from "../../services/auth";
import {
  createReminder,
  getAllReminder,
} from "../../store/reminder/reminderActions";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CachedIcon from "@mui/icons-material/Cached";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import CloseIcon from "@mui/icons-material/Close";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { DayCalendarSkeleton, PickersDay } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import CommonDatePicker from "../../common/CommonDatePicker";
import { capitalizeFirstLetter, formatBytes } from "../../helpers/functions";
import { getProperties } from "../../store/property/propertyActions";
import BreadcrumbsRoutes from "../BreadCrumpsRoutes";
import Notifications from "../Notifications";
import { useMediaQuery } from "@mui/material";
import { formatSize } from "../../helpers/functions";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 2 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const drawerWidth = 240;
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${0}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}
const stringAvatar = (firstName, lastName) => {
  if (firstName && lastName) {
    const initials = `${firstName[0]}${lastName[0]}`;
    return (
      <Avatar sx={{ bgcolor: "#24003D", color: "#fff" }}>{initials}</Avatar>
    );
  } else {
    return <Avatar>{/* Default Avatar content */}</Avatar>;
  }
};

function getRandomNumber(min, max) {
  return Math.round(Math.random() * (max - min) + min);
}

function fakeFetch(date, { signal }) {
  return new Promise((resolve, reject) => {
    const timeout = setTimeout(() => {
      const daysInMonth = date.daysInMonth();
      const daysToHighlight = [1, 2, 3].map(() =>
        getRandomNumber(1, daysInMonth)
      );

      resolve({ daysToHighlight });
    }, 500);

    signal.onabort = () => {
      clearTimeout(timeout);
      reject(new DOMException("aborted", "AbortError"));
    };
  });
}

const initialValue = dayjs();

function ServerDay(props) {
  const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;

  const isSelected =
    !props.outsideCurrentMonth &&
    highlightedDays.indexOf(props.day.date()) >= 0;

  return (
    <Badge
      key={props.day.toString()}
      overlap="circular"
      badgeContent={isSelected ? <div class="circle-emoji"></div> : undefined}
    >
      <PickersDay
        {...other}
        outsideCurrentMonth={outsideCurrentMonth}
        day={day}
      />
    </Badge>
  );
}

const Home = ({ children }) => {
  const requestAbortController = React.useRef(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [highlightedDays, setHighlightedDays] = React.useState([1, 2, 15]);
  const drawerRef = useRef(null);

  const fetchHighlightedDays = (date) => {
    const controller = new AbortController();
    fakeFetch(date, {
      signal: controller.signal,
    })
      .then(({ daysToHighlight }) => {
        setHighlightedDays(daysToHighlight);
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.name !== "AbortError") {
          throw error;
        }
      });

    requestAbortController.current = controller;
  };

  React.useEffect(() => {
    fetchHighlightedDays(initialValue);
    return () => requestAbortController.current?.abort();
  }, []);

  const handleMonthChange = (date) => {
    if (requestAbortController.current) {
      requestAbortController.current.abort();
    }

    setIsLoading(true);
    setHighlightedDays([]);
    fetchHighlightedDays(date);
  };

  const [data, setData] = useState({
    topic: "",
    date: new Date(),
    notes: "",
    recurringReminder: false,
    frequency: "",
    endDate: new Date(),
  });
  const [notificationValue, setNotificationValue] = React.useState(0);
  const [calenderValue, setCalenderValue] = React.useState(0);

  const handleNotificationChange = (event, newValue) => {
    setNotificationValue(newValue);
  };

  const handleCalenderChange = (event, newValue) => {
    setCalenderValue(newValue);
  };

  const handleDateChange = (newValue) => {
    setData({ ...data, date: newValue });
  };

  const [value, setValue] = React.useState(0);
  const [usedSize, setUsedSize] = React.useState(0);
  const [totalSize, setTotalSize] = React.useState("");
  const properties = useSelector((state) => state.properties.properties);
  const [percentage, setPercentage] = React.useState(0);
  const [toolopen, setToolOpen] = useState(false);
  const [calanderOpen, setCalanderOpen] = useState(false);
  const [notificationOpen, setNotificationCalanderOpen] = useState(false);
  const [helpOpen, setHelpOpen] = useState(false);

  const handleMouseEnter = () => {
    setToolOpen(true);
  };
  const handleMouseLeave = () => {
    setToolOpen(false);
  };

  const handleCalenderEnter = () => {
    setCalanderOpen(true);
  };
  const handleCalenderLeave = () => {
    setCalanderOpen(false);
  };

  const handleHelpEnter = () => {
    setHelpOpen(true);
  };
  const handleHelpLeave = () => {
    setHelpOpen(false);
  };

  const getHeadersTitle = () => {
    const pathname = location.pathname;

    if (pathname === "/lvr-projections") {
      return "LVR Projections";
    }
    if (pathname === "/add-property") {
      return "Add Property";
    }
    if (pathname === "/add-document") {
      return "Add Document";
    }

    if (pathname.includes("edit-property") && propertyType) {
      return propertyType == "investmentProperty"
        ? "Edit Invesment Property"
        : `Edit ${capitalizeEachWord(propertyType)} Property`;
    } else {
      return getHeaderTitle(pathname);
    }
  };
  const onAddDocument = async () => {
    try {
      dispatch(createReminder({ ...data })).then((response) => {
        if (response?.payload?.success) {
          setTimeout(() => {
            handleRemiderClose();
            setData({
              topic: "",
              date: new Date(),
              notes: "",
              recurringReminder: "",
              frequency: "",
              endDate: new Date(),
            });
          }, 500);
        }
      });
    } catch (error) {
      console.error("Error occurred during validation:", error);
    }
  };
  const getHeaderTitle = (key) => {
    switch (key) {
      case "/add-owner-occupied-property":
        return "Add Owner Occupied Property Details";
        break;
      case "/financial-position-assets-and-liabilities-statement":
        return "Financial Position - Assets & Liabilities Statement";
        break;
      case "/income-and-expense-breakdown-statement":
        return "Income & Expense Breakdown Statement";
        break;
      case "/profit-and-loss-summary":
        return "P&L Summary";
        break;
      case "/eofy Statement":
      case "/EOFY-statement":
        return "EOFY Statement";
        break;
      case "/rental-yield":
        return "Rental Yield";
        break;
      default:
        return capitalize(key.split("/")[1].split("-").join(" "));
        break;
    }
  };

  const dispatch = useDispatch();
  const location = useLocation();
  let id = useParams();
  const [propertyParamsId, setPropertyParamsId] = useState("");
  const [personalProperty, setPersonalProperty] = useState("");
  const profiles = useSelector((state) => state.profiles.profiles);
  const reminders = useSelector((state) => state.reminders.allReminders);
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);
  const pastReminders = reminders.filter((reminder) => {
    const reminderDate = new Date(reminder.date);
    return reminderDate < currentDate;
  });
  const futureReminders = reminders.filter((reminder) => {
    const reminderDate = new Date(reminder.date);
    return reminderDate >= currentDate;
  });
  const { FINANCIAL_YEARS } = useSelector((state) => state.common.data);
  const { REMINDERS_FREQUENCY } = useSelector((state) => state.common.data);

  const capitalizeEachWord = (str) => {
    return str
      .split("-") // Split the string into words based on '-'
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
      .join(" "); // Join the words back together with space
  };

  const pathname = location.pathname;
  const capitalizedPathname = capitalizeEachWord(pathname);

  const [state, setState] = React.useState({
    right: false,
  });
  const [reminderState, setReminderState] = React.useState({
    right: false,
  });
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  const handleDrawerToggle = () => {
    setOpen(!open);
  };
  const toggleReminderDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setReminderState({ ...reminderState, [anchor]: open });
  };

  const showButton = capitalizedPathname.includes("/property Details/");

  const [auth, setAuth] = React.useState(true);

  const handleChange = (event) => {
    setAuth(event.target.checked);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const dashboardPath = "/dashboard";

  const shouldShowBackButton = location.pathname !== dashboardPath;

  const goBack = () => {
    window.history.back();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onLogout = async () => {
    await cookies.remove("SID");
    window.location.reload(true);
    setTimeout(() => {
      navigate(`/`);
    }, 1000);
  };

  const getDocument = async () => {
    const response = await apiRequest({
      url: "document",
      method: "get",
    });
    if (response?.success && response?.data) {
      setUsedSize(response?.data.usedSize);
      setPercentage(response?.data.percentage);
      setTotalSize(response?.data.totalSize);
    }
  };

  const handleLogout = () => {
    cookies.remove("SID");

    navigate("/login");

    handleClose();
    handleDrawerToggle();
  };
  const propertyType = useSelector((state) =>
    state.properties.property._id
      ? state.properties.property.property.type
      : null
  );
  useEffect(() => {
    setPropertyParamsId(id.propertyId);
  }, [id]);
  useEffect(() => {
    getDocument();
  }, []);
  useEffect(() => {
    dispatch(getAllReminder());
  }, []);

  useEffect(() => {
    dispatch(
      getProperties({
        sort: `property.type|1`,
      })
    );
  }, []);

  useEffect(() => {
    const selectedProp = properties.find(
      (prop) => prop._id === propertyParamsId
    );
    if (selectedProp) {
      if (selectedProp.property.personalProperty === true) {
        setPersonalProperty(selectedProp);
      } else {
        setPersonalProperty(null);
      }
    }
  }, [propertyParamsId, properties]);

  const navigate = useNavigate();
  const [page, setPages] = React.useState("Jobs");
  const theme = useTheme();
  // const [open, setOpen] = React.useState(false);
  const isMobileScreen = useMediaQuery("(max-width:600px)");
  const [open, setOpen] = React.useState(true);

  const [openReminder, setOpenRemider] = React.useState(false);
  const handleRemiderClickOpen = () => {
    setOpenRemider(true);
  };
  const handleRemiderClose = () => {
    setOpenRemider(false);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleClickOutside = (event) => {
    if (drawerRef.current && !drawerRef.current.contains(event.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setOpen(true);
  }, [!isMobileScreen]);


  const mobileMenuId = "primary-search-account-menu-mobile";

  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton size="large" color="inherit">
          <Badge color="error">
            <SettingsOutlinedIcon />
          </Badge>
        </IconButton>
        <p>Setting</p>
      </MenuItem>
      <MenuItem>
        <IconButton
          size="large"
          aria-label="show 5 new notifications"
          color="inherit"
        >
          <Badge badgeContent={5} color="error">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  const list = (anchor) => (
    <Box
      sx={{
        minHeight: "100vh",
        position: "relative",
        width: anchor === "top" || anchor === "bottom" ? "auto" : 380,
      }}
      role="presentation"
    >
      <Stack px={2}>
        <List>
          <Stack
            direction={"row"}
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Stack
              direction={"row"}
              spacing={1}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <CalendarTodayIcon sx={{ fontSize: 20 }} />
              <Typography sx={{ fontWeight: "bold" }}> Calendar</Typography>
            </Stack>
            <CloseIcon
              onClick={toggleDrawer(anchor, false)}
              sx={{ fontSize: 20, cursor: "pointer" }}
            />
          </Stack>
        </List>
      </Stack>
      <Divider />
      <Stack sx={{ backgroundColor: "#edeff6", padding: 0 }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateCalendar
            defaultValue={initialValue}
            loading={isLoading}
            onMonthChange={handleMonthChange}
            renderLoading={() => <DayCalendarSkeleton />}
            slots={{
              day: ServerDay,
            }}
            slotProps={{
              day: {
                highlightedDays,
              },
            }}
          />
        </LocalizationProvider>
      </Stack>
      <Stack
        sx={{
          position: "absolute",
          bottom: 15,
          right: 2,
          padding: 1.5,
          borderRadius: "50px",
        }}
      >
        <Button
          variant="contained"
          disableElevation
          onClick={handleRemiderClickOpen}
          sx={{
            border: "1px solid lightgray",
            width: "fit-content",
            fontWeight: 600,
            color: "#fff",
            bgcolor: "#24003D !important",
            border: "1px solid #24003D !important",
            textTransform: "none",
            borderRadius: "5px",
            px: 3,
            boxShadow:
              "rgba(17, 17, 17, 0.04) 0px 2px 4px 0px, rgba(33, 33, 33, 0.08) 0px 8px 32px 0px",
            transition: "box-shadow 0.3s ease",
            "&:hover": {
              boxShadow: "none",
            },
          }}
        >
          + New Reminder
        </Button>
      </Stack>
    </Box>
  );

  const Reminders = (anchor) => (
    <Box
      sx={{
        minHeight: "100vh",
        position: "relative",
        width: anchor === "top" || anchor === "bottom" ? "auto" : 380,
      }}
      role="presentation"
    >
      <Stack px={2}>
        <List>
          <Stack
            direction={"row"}
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Stack
              direction={"row"}
              spacing={1}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <NotificationsIcon sx={{ fontSize: 20 }} />
              <Typography sx={{ fontWeight: "bold" }}> Reminders</Typography>
            </Stack>
            <CloseIcon
              sx={{ fontSize: 20, cursor: "pointer" }}
              onClick={toggleReminderDrawer(anchor, false)}
            />
          </Stack>
        </List>
      </Stack>
      <Divider />
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={notificationValue}
          onChange={handleNotificationChange}
          aria-label="basic tabs example"
        >
          <Tab label="ALL" {...a11yProps(0)} />
          <Tab label="OVERDUE" {...a11yProps(1)} />
          <Tab label="UPCOMING" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={notificationValue} index={0}>
        <Stack
          spacing={2}
          sx={{
            position: "relative",
          }}
        >
          {reminders.map((item) => (
            <Stack>
              <Stack
                direction={"row"}
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  border: "solid",
                  borderWidth: "2px",
                  borderColor: "#edeff6",
                  borderRadius: 2,
                  borderLeft: "solid",
                  borderLeftWidth: "5px",
                  borderLeftColor: "#24003d",
                }}
              >
                <Stack p={1}>
                  <Stack direction={"row"} spacing={1}>
                    <Typography sx={{ fontWeight: "bold" }}>
                      {item.topic}
                    </Typography>
                    <CachedIcon sx={{ fontSize: 20 }} />
                  </Stack>
                  <Typography> {item.notes}</Typography>
                </Stack>
                <Stack
                  direction={"row"}
                  sx={{ display: "flex", alignItems: "center" }}
                  spacing={1}
                >
                  <Typography>
                    {" "}
                    {capitalizeFirstLetter(item.frequency)}
                  </Typography>
                  <ArrowForwardIosIcon sx={{ padding: 1, fontSize: 30 }} />
                </Stack>
              </Stack>
            </Stack>
          ))}
        </Stack>
      </CustomTabPanel>
      <CustomTabPanel value={notificationValue} index={1}>
        <Stack spacing={2}>
          {pastReminders.map((item) => (
            <Stack>
              <Stack
                direction={"row"}
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  border: "solid",
                  borderWidth: "2px",
                  borderColor: "#edeff6",
                  borderRadius: 2,
                  borderLeft: "solid",
                  borderLeftWidth: "5px",
                  borderLeftColor: "#24003d",
                }}
              >
                <Stack p={1}>
                  <Stack direction={"row"} spacing={1}>
                    <Typography sx={{ fontWeight: "bold" }}>
                      {item.topic}
                    </Typography>
                    <CachedIcon sx={{ fontSize: 20 }} />
                  </Stack>
                  <Typography> {item.notes}</Typography>
                </Stack>
                <Stack
                  direction={"row"}
                  sx={{ display: "flex", alignItems: "center" }}
                  spacing={1}
                >
                  <Typography>
                    {" "}
                    {capitalizeFirstLetter(item.frequency)}
                  </Typography>
                  <ArrowForwardIosIcon sx={{ padding: 1, fontSize: 30 }} />
                </Stack>
              </Stack>
            </Stack>
          ))}
        </Stack>
      </CustomTabPanel>
      <CustomTabPanel value={notificationValue} index={2}>
        <Stack spacing={2}>
          {futureReminders.map((item) => (
            <Stack>
              <Stack
                direction={"row"}
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  border: "solid",
                  borderWidth: "2px",
                  borderColor: "#edeff6",
                  borderRadius: 2,
                  borderLeft: "solid",
                  borderLeftWidth: "5px",
                  borderLeftColor: "#24003d",
                }}
              >
                <Stack p={1}>
                  <Stack direction={"row"} spacing={1}>
                    <Typography sx={{ fontWeight: "bold" }}>
                      {item.topic}
                    </Typography>
                    <CachedIcon sx={{ fontSize: 20 }} />
                  </Stack>
                  <Typography> {item.notes}</Typography>
                </Stack>
                <Stack
                  direction={"row"}
                  sx={{ display: "flex", alignItems: "center" }}
                  spacing={1}
                >
                  <Typography>
                    {" "}
                    {capitalizeFirstLetter(item.frequency)}
                  </Typography>
                  <ArrowForwardIosIcon sx={{ padding: 1, fontSize: 30 }} />
                </Stack>
              </Stack>
            </Stack>
          ))}
        </Stack>
      </CustomTabPanel>
    </Box>
  );
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        sx={{
          boxShadow: "unset",
        }}
        className="background"
        color="inherit"
        position="fixed"
        open={open}
      >
        <Toolbar>
          <Box></Box>
          <Box width={"100%"}>
            <IconButton
              onClick={handleDrawerToggle}
              sx={{ display: { md: "none", xs: "flex" }, color: "#000" }}
            >
              <MenuIcon />
            </IconButton>
            <Stack
              direction={"row"}
              alignItems={"center"}
              spacing={1}
              sx={{ display: { md: "flex", xs: "none" } }}
            >
              {shouldShowBackButton && (
                <IconButton onClick={() => goBack()}>
                  <KeyboardBackspaceIcon
                    sx={{
                      color: "#24003D",
                      cursor: "pointer",
                    }}
                  />
                </IconButton>
              )}
              <Stack>
                <Typography
                  sx={{
                    fontWeight: 500,

                    fontSize: "25px",
                  }}
                >
                  <> {getHeadersTitle(capitalizedPathname)}</>
                </Typography>
                <BreadcrumbsRoutes />
              </Stack>
            </Stack>
          </Box>

          <Stack direction={"row"} spacing={1} alignItems={"center"}>
            {/* {["right"].map((anchor) => (
              <React.Fragment key={anchor}>
                <Tooltip
                  title="Calendar"
                  open={calanderOpen}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                >
                  <CalendarTodayIcon
                    onMouseEnter={handleCalenderEnter}
                    onMouseLeave={handleCalenderLeave}
                    onClick={toggleDrawer(anchor, true)}
                    sx={{ fontSize: 18, cursor: "pointer" }}
                  />
                </Tooltip>

                <SwipeableDrawer
                  anchor={anchor}
                  open={state[anchor]}
                  // onClose={toggleDrawer(anchor, false)}
                  onOpen={toggleDrawer(anchor, true)}
                  sx={{
                    "& .MuiDrawer-paper": {
                      borderTopLeftRadius: "20px",
                    },
                  }}
                >
                  {list(anchor)}
                </SwipeableDrawer>
              </React.Fragment>
            ))}
            <Notifications /> */}
            <Tooltip
              title="Quick Help"
              open={helpOpen}
              disableFocusListener
              disableHoverListener
              disableTouchListener
            >
              <LightbulbIcon
                onClick={() => {
                  // navigate("/help");
                  navigate("/help", { state: { faq: false } });
                }}
                onMouseEnter={handleHelpEnter}
                onMouseLeave={handleHelpLeave}
                sx={{ fontSize: 20, cursor: "pointer" }}
              />
            </Tooltip>
          </Stack>

          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            <Box>
              <div>
                <Stack direction={"row"} alignItems={"center"}>
                  <Tooltip
                    title="User Profile"
                    open={toolopen}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                  >
                    <IconButton
                      size="large"
                      aria-label="account of current user"
                      aria-controls="menu-appbar"
                      aria-haspopup="true"
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                      onClick={handleMenu}
                      sx={{
                        "&:hover": {
                          backgroundColor: "transparent",
                          opacity: 1,
                        },
                      }}
                      color="inherit"
                    >
                      <Stack
                        style={{
                          border: "3px solid #24003D",
                          // display: "inline-block",
                          borderRadius: "5px",
                          padding: 2,
                          textTransform: "capitalize",
                          maxWidth: "200px",
                          // minWidth:"100%",
                        }}
                        direction={"row"}
                        alignItems={"center"}
                        spacing={1}
                      >
                        <div>
                          {profiles &&
                          profiles?.firstName &&
                          profiles?.lastName ? (
                            stringAvatar(
                              capitalize(profiles.firstName),
                              capitalize(profiles.lastName)
                            )
                          ) : (
                            <span>N/A</span>
                          )}
                        </div>
                        <Stack
                          style={{ overflow: "hidden" }}
                          direction={"row"}
                          spacing={1}
                        >
                          <Typography
                            sx={{
                              cursor: "pointer",
                              fontWeight: 600,
                              color: "#0f2e5a",
                              fontSize: "19px",
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              maxWidth: "100%",
                            }}
                          >
                            {profiles.firstName} {profiles.lastName}
                          </Typography>
                        </Stack>
                      </Stack>
                    </IconButton>
                  </Tooltip>
                </Stack>

                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem
                    onClick={() => {
                      navigate("/settings");
                      handleClose();
                      handleDrawerToggle();
                    }}
                  >
                    Settings
                  </MenuItem>
                  <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </Menu>
              </div>
            </Box>
          </Box>
          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          height: "100vh",
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        // variant="persistent"
        variant={window.innerWidth < 600 ? "temporary" : "persistent"}
        anchor="left"
        open={window.innerWidth < 600 ? open : true}
        className="background"
      >
        <DrawerHeader
          className="background"
          sx={{
            bgcolor: "#fff",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Stack
            spacing={1}
            mt={"10px"}
            mb={"10px"}
            direction={"row"}
            sx={{ cursor: "pointer" }}
            onClick={() => {
              navigate("/");
              setOpen(false);
            }}
          >
            <img src={Logo} alt="logo" height={'auto'} width={210} />
            {/* <Typography sx={{ color: "#fff", fontWeight: 600, fontSize: 20 }}>
              InvestPulse
            </Typography> */}
          </Stack>
          {window.innerWidth < 600 && (
            <IconButton onClick={handleDrawerToggle} sx={{ color: "#000" }}>
              {/* <MenuIcon /> */}
              <CloseIcon />
            </IconButton>
          )}
        </DrawerHeader>
        <Stack
          className="background"
          sx={{ height: "100vh", justifyContent:'space-between', bgcolor: "#24003D" ,}}
        >
          <List className="sidebar-button">
            <ListItem  disablePadding sx={{ display: "block" }}>
                <Stack>
                  <ListItemButton
                    onClick={() => {
                      navigate("/dashboard");
                      setOpen(false);
                    }}
                    sx={{
                      minHeight: 48,
                      // minHeight: open ? 48 : 48,
                      marginTop: 1,
                      justifyContent: open ? "initial" : "initial",
                      px: 4,
                      py: 1.3,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: open ? 0 : 0,
                        mr: open ? 1 : 1,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <DashboardIcon sx={{ color: "white" }} />
                    </ListItemIcon>
                    <Typography
                      sx={{
                        opacity: open ? 1 : 1,
                        color: "#fff",
                        fontWeight: "bold",
                      }}
                    >
                      Dashboard
                    </Typography>
                  </ListItemButton>
                  <ListItemButton
                    onClick={() => {
                      navigate("/property");
                      setOpen(false);
                    }}
                    sx={{
                      minHeight: 48,
                      marginTop: 1,
                      justifyContent: open ? "initial" : "initial",
                      px: 4,
                      py: 1.3,
                      // gap:1
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: open ? 0 : 0,
                        mr: open ? 1 : 1,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <MapsHomeWorkIcon sx={{ color: "white" }} />
                    </ListItemIcon>
                    <Typography
                      sx={{
                        opacity: open ? 1 : 1,
                        color: "#fff",
                        fontWeight: "bold",
                      }}
                    >
                      Property
                    </Typography>
                  </ListItemButton>
                  <ListItemButton
                    onClick={() => {
                      navigate("/reports");
                      setOpen(false);
                    }}
                    sx={{
                      minHeight: 48,
                      marginTop: 1,
                      justifyContent: open ? "initial" : "initial",
                      px: 4,
                      py: 1.3,
                      // gap:1
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: open ? 0 : 0,
                        mr: open ? 1 : 1,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <AnalyticsIcon sx={{ color: "white" }} />
                    </ListItemIcon>
                    <Typography
                      sx={{
                        opacity: open ? 1 : 1,
                        color: "#fff",
                        fontWeight: "bold",
                      }}
                    >
                      Reports
                    </Typography>
                  </ListItemButton>
                  <ListItemButton
                    onClick={() => {
                      navigate("/documents");
                      setOpen(false);
                    }}
                    sx={{
                      minHeight: 48,
                      marginTop: 1,
                      justifyContent: open ? "initial" : "initial",
                      px: 4,
                      py: 1.3,
                      // gap:1
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: open ? 0 : 0,
                        mr: open ? 1 : 1,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <DocumentScanner sx={{ color: "white" }} />
                    </ListItemIcon>
                    <Typography
                      sx={{
                        opacity: open ? 1 : 1,
                        color: "#fff",
                        fontWeight: "bold",
                      }}
                    >
                      Documents
                    </Typography>
                  </ListItemButton>
                </Stack>
            </ListItem>
          </List>
          <List className="sidebar-button">
            <ListItem  disablePadding sx={{ display: "block" }}>
                <Stack>
                  <ListItemButton
                    onClick={() => {
                      navigate("/help", { state: { faq: true } });
                      setOpen(false);
                    }}
                    sx={{
                      minHeight: 48,
                      marginTop: 1,
                      justifyContent: open ? "initial" : "initial",
                      px: 4,
                      py: 1.3,
                      // gap:1
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: open ? 0 : 0,
                        mr: open ? 1 : 1,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <HelpIcon sx={{ color: "white" }} />
                    </ListItemIcon>
                    <Typography
                      sx={{
                        opacity: open ? 1 : 1,
                        color: "#fff",
                        fontWeight: "bold",
                      }}
                    >
                      Help
                    </Typography>
                  </ListItemButton>

                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      marginTop: 1,
                      justifyContent: open ? "initial" : "initial",
                      px: 4,
                      py: 1.3,
                      // gap:1
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: open ? 0 : 0,
                        mr: open ? 1 : 1,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <DonutSmallIcon sx={{ color: "white" }} />
                    </ListItemIcon>
                    <Typography
                      sx={{
                        opacity: open ? 1 : 1,
                        color: "#fff",
                        whiteSpace: "nowrap",
                        fontWeight: "bold",
                      }}
                    >
                      Data Usage - {profiles?.dataUsage?.percentage}%
                    </Typography>
                  </ListItemButton>
                  <Stack spacing={1} alignItems={"center"}>
                    <BorderLinearProgress
                      variant="determinate"
                      value={profiles?.dataUsage?.percentage}
                      style={{
                        width: "70%",
                      }}
                    />
                    <Typography
                      sx={{
                        cursor: "pointer",
                        fontWeight: 600,
                        color: "#fff",
                        fontSize: "12px",
                        textAlign: "start",
                      }}
                    >
                      <span>
                        {profiles?.dataUsage?.usedSize &&
                          formatSize(profiles?.dataUsage?.usedSize)}{" "}
                        of{" "}
                        {profiles?.dataUsage?.totalSize &&
                          formatBytes(profiles?.dataUsage?.totalSize)}
                      </span>
                    </Typography>
                  </Stack>
                  <ListItemButton
                    onClick={() => {
                      navigate("/settings");
                      setOpen(false);
                    }}
                    sx={{
                      minHeight: 48,
                      marginTop: 1,
                      justifyContent: open ? "initial" : "initial",
                      px: 4,
                      py: 1.3,
                      // gap:1
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: open ? 0 : 0,
                        mr: open ? 1 : 1,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <SettingsApplicationsIcon sx={{ color: "white" }} />
                    </ListItemIcon>
                    <Typography
                      sx={{
                        opacity: open ? 1 : 1,
                        color: "#fff",
                        fontWeight: "bold",
                      }}
                    >
                      Settings
                    </Typography>
                  </ListItemButton>
                  <ListItemButton
                    onClick={() => {
                      cookies.remove("SID");

                      navigate("/login");
                      onLogout();
                      setOpen(false);
                    }}
                    sx={{
                      minHeight: 48,
                      marginTop: 1,
                      justifyContent: open ? "initial" : "initial",
                      px: 4,
                      py: 1.3,
                      // gap:1
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: open ? 0 : 0,
                        mr: open ? 1 : 1,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <LogoutIcon sx={{ color: "white" }} />
                    </ListItemIcon>
                    <Typography
                      sx={{
                        opacity: open ? 1 : 1,
                        color: "#fff",
                        fontWeight: "bold",
                      }}
                      onClick={()=>{
                        cookies.remove("SID");
                        navigate("/login");
                      }}
                    >
                      Logout
                    </Typography>
                  </ListItemButton>
                </Stack>
            </ListItem>
          </List>
        </Stack>
      </Drawer>

      <Main
        sx={{
          backgroundColor: "#f6f6f6",
          maxWidth: "100%",
          overflowX: "hidden",
        }}
        open={open}
        page={page}
      >
        {children}
      </Main>
      {renderMobileMenu}

      <React.Fragment>
        <Dialog
          open={openReminder}
          onClose={handleRemiderClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Add reminder"}</DialogTitle>
          <DialogContent>
            <Stack sx={{ width: "400px" }}>
              <Stack>
                <Typography
                  sx={{
                    color: "#000",
                    fontWeight: 600,
                    fontSize: "16px",
                    mb: 1,
                  }}
                >
                  Topic
                </Typography>
                <TextField
                  // disabled={isDisable === true ? true : false}
                  sx={{
                    "& .MuiInputBase-input": { height: "1.5rem" },
                    marginTop: "0px",
                  }}
                  InputProps={{
                    sx: {
                      borderTopLeftRadius: "0rem",
                      borderBottomLeftRadius: "0rem",
                      borderRadius: 5,
                      boxShadow: 3,
                    },
                  }}
                  size="small"
                  margin="normal"
                  variant="outlined"
                  type="text"
                  name="description"
                  placeholder="Topic"
                  fullWidth
                  value={data.topic}
                  onChange={(event) => {
                    const updatedDescription = event.target.value;
                    setData({
                      ...data,
                      topic: updatedDescription,
                    });
                  }}
                />
              </Stack>

              <Stack
                spacing={2}
                direction={"row"}
                sx={{
                  width: "100%",
                  isplay: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Stack>
                  <Typography
                    sx={{
                      color: "#000",
                      fontWeight: 600,
                      fontSize: "16px",
                      mb: 1,
                    }}
                  >
                    Date & Time
                  </Typography>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["DateTimePicker"]}>
                      <DateTimePicker
                        value={dayjs(data.date)}
                        onChange={handleDateChange}
                        renderInput={(params) => <TextField {...params} />}
                        inputStyle={{ height: "10px" }}
                        views={["year", "month", "day", "hours", "minutes"]}
                        style={{
                          borderRadius: "20px",
                        }}
                        // label="Date and time"
                        format="DD/MM/YYYY hh:mm A"
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </Stack>
              </Stack>
              <Stack>
                <Typography
                  py={1}
                  sx={{
                    color: "#000",
                    fontWeight: 600,
                    fontSize: "16px",
                  }}
                >
                  Notes
                </Typography>
                <TextField
                  id="outlined-multiline-static"
                  multiline
                  rows={3}
                  placeholder="Enter notes"
                  value={data.notes}
                  onChange={(event) => {
                    const updatedNotes = event.target.value;
                    setData({
                      ...data,
                      notes: updatedNotes,
                    });
                  }}
                />
              </Stack>

              <Grid container spacing={2} mt={1}>
                <Grid item xs={7}>
                  <Typography
                    mt={1}
                    sx={{
                      color: "#000",
                      fontWeight: 600,
                      fontSize: "16px",
                    }}
                  >
                    Is this a recurring reminder?
                  </Typography>
                </Grid>

                <Grid item xs={5}>
                  <Stack spacing={2}>
                    <FormControlLabel
                      control={
                        <CustomSwitch
                          value={data?.recurringReminder == true ? "yes" : "no"}
                          onChange={(event) => {
                            setData({
                              ...data,
                              recurringReminder:
                                event.target.value == "yes" ? true : false,
                            });
                            // setShowMessage(
                            //   event.target.value == "yes" ? true : false
                            // );
                          }}
                        />
                      }
                    />
                  </Stack>
                </Grid>
              </Grid>
              <Stack sx={{ width: "100%" }} py={1}>
                {data?.recurringReminder && (
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Stack>
                        <FormControl fullWidth>
                          <Typography
                            sx={{
                              color: "#000",
                              fontWeight: 600,
                              fontSize: "16px",
                            }}
                          >
                            Frequency
                          </Typography>

                          <CommonSelect
                            label=""
                            value={data.frequency}
                            onChange={(event) => {
                              setData({
                                ...data,
                                frequency: event.value,
                              });
                            }}
                            options={REMINDERS_FREQUENCY}
                            size="small"
                            variant="outlined"
                            sx={{
                              "& .MuiSelect-select": {
                                borderTopRightRadius: "0rem",
                                borderBottomRightRadius: "0rem",
                                fontSize: "14px",
                              },
                            }}
                          />
                        </FormControl>
                      </Stack>
                    </Grid>
                    <Grid item xs={6}>
                      <Stack>
                        <Typography
                          sx={{
                            color: "#000",
                            fontWeight: 600,
                            fontSize: "16px",
                          }}
                        >
                          End Date
                        </Typography>
                        <CommonDatePicker
                          style={{
                            borderRadius: "20px",
                          }}
                          value={data.endDate}
                          onChange={(event) => {
                            const updatedEndDate = event;
                            setData({
                              ...data,
                              endDate: updatedEndDate,
                            });
                          }}
                        />
                      </Stack>
                    </Grid>
                  </Grid>
                )}
              </Stack>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Stack direction={"row"} spacing={1} sx={{ display: "flex" }}>
              <Button
                onClick={handleRemiderClose}
                sx={{
                  borderColor: "#24003d",
                  color: "#24003d",
                  border: "solid",
                  borderWidth: 1,
                }}
              >
                Cancel
              </Button>

              <Button
                onClick={onAddDocument}
                variant="contained"
                disableElevation
                sx={{ backgroundColor: "#24003d" }}
              >
                Save
              </Button>
            </Stack>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    </Box>
  );
};

export default Home;
