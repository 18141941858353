import { createAsyncThunk } from "@reduxjs/toolkit";
import apiRequest from "../../services/auth";

export const getProfile = createAsyncThunk(
  "user/profile",
  async (data, { dispatch }) => {
    const res = await apiRequest({
      url: "user/profile",
      method: "GET",
      data,
    }).then((response) => {
      return response;
    });
    return res;
  }
);

export const updateProfile = createAsyncThunk(
  "user/profile/update",
  async (data, { dispatch }) => {
    const res = await apiRequest({
      url: `user/profile/update`,
      method: "PUT",
      data: data.data.updateData,
    }).then((response) => {
      ;
      return response;
    });
    return res;
  }
);
