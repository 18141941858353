import React from "react";
import Marketplace from "../pages/PropertiesPages/Marketplace";
import MarketSearchResults from "../pages/PropertiesPages/MarketSearchResults";
import CapitalGrowthCalculator from "../pages/ReportPages/CapitalForecastCalculator";
import CashFlowProjections from "../pages/ReportPages/CashFlowProjections";
import EofyView from "../pages/ReportPages/EofyView";
import LoanRepaymentCalculator from "../pages/ReportPages/LoanRepaymentCalculator";
import LVRProjections from "../pages/ReportPages/LVRProjections";
import RefinanceCalculator from "../pages/ReportPages/RefinanceCalculator";
import PublicRefinanceCalculator from "../pages/HomePages/PublicRefinanceCalculator";
import PublicLoanRepaymentCalculator from "../pages/HomePages/PublicLoanRepaymentCalculator";
import RentalYieldView from "../pages/ReportPages/RentalYieldView";
import PrivacyPolicy from "../pages/HomePages/PrivacyPolicy";
import TermsAndConditions from "../pages/HomePages/TermsAndConditions";
import SaasAgreement from "../pages/HomePages/SaasAgreement";
import CookiePolicy from "../pages/HomePages/CookiePolicy";
const Documents = React.lazy(() =>
  import("../pages/PropertiesPages/Documents")
);
const YourAccountActivated = React.lazy(() =>
  import("../components/YourAccountActivated")
);

const Login = React.lazy(() => import("../pages/Auth/Login/Login"));
const Signup = React.lazy(() => import("../pages/Auth/Signup/Signup"));
const SideBarWithTopBar = React.lazy(() =>
  import("../components/SidebarWithTopBar")
);
const ForgetPassword = React.lazy(() => import("../pages/Auth/ForgetPassword"));
const Home = React.lazy(() => import("../pages/HomePages/Home"));
const ResetPassword = React.lazy(() => import("../pages/Auth/ResetPassword"));
const ProfileCreations = React.lazy(() =>
  import("../pages/Profile/ProfileCreations")
);
const AddProperty = React.lazy(() =>
  import("../pages/PropertiesPages/AddProperty")
);
const AddSelfProperty = React.lazy(() =>
  import("../pages/PropertiesPages/AddSelfProperty")
);
const PropertyDetails = React.lazy(() =>
  import("../pages/PropertiesPages/PropertyDetails")
);
const Dashboard = React.lazy(() => import("../pages/Dashboard"));
const Help = React.lazy(() => import("../pages/HomePages/Help"));
const DataUsage = React.lazy(() => import("../pages/HomePages/DataUsage"));
const Reports = React.lazy(() => import("../pages/ReportPages/Reports"));
const Settings = React.lazy(() => import("../pages/Profile/Settings"));
const BulkUpload = React.lazy(() =>
  import("../pages/PropertiesPages/BulkUpload/Income")
);
const BreakdownStatement = React.lazy(() =>
  import("../pages/ReportPages/BreakdownStatement")
);
const Property = React.lazy(() => import("../pages/PropertiesPages/Property"));
const Contact = React.lazy(() => import("../pages/HomePages/Contact"));
const OurStory = React.lazy(() => import("../pages/HomePages/OurStory"));
const Resources = React.lazy(() => import("../pages/HomePages/Resources"));
const Calculator = React.lazy(() => import("../pages/HomePages/Calculator"));

const PublicCapitalGrowth = React.lazy(() =>
  import("../pages/HomePages/PublicCapitalGrowth")
);
const About = React.lazy(() => import("../pages/HomePages/AboutUs"));
const Testimonials = React.lazy(() =>
  import("../pages/HomePages/Testimonials")
);
const Features = React.lazy(() => import("../pages/HomePages/Features"));
const ViewDocuments = React.lazy(() =>
  import("../pages/PropertiesPages/ViewDocuments")
);
const Statement = React.lazy(() =>
  import("../pages/PropertiesPages/Statement")
);
const RentalAccounting = React.lazy(() =>
  import("../pages/HomePages/RentalAccounting/RentalAccounting")
);
const Faq = React.lazy(() => import("../pages/HomePages/Faq/index"));
const FeatureRequest = React.lazy(() =>
  import("../pages/HomePages/FeatureRequest/FeatureRequest")
);
const LearnAndSupport = React.lazy(() =>
  import("../pages/HomePages/LearnAndSupport/LearnAndSupport")
);
const PartnerAffiliation = React.lazy(() =>
  import("../pages/ReportPages/PartnerAffiliation/PartnerAffiliation")
);
const ReferralProgram = React.lazy(() =>
  import("../pages/HomePages/ReferralProgram/ReferralProgram")
);
const Pricing = React.lazy(() => import("../pages/HomePages/Pricing/Pricing"));
const Careers = React.lazy(() => import("../pages/HomePages/Careers/Careers"));
const Demo = React.lazy(() => import("../pages/HomePages/Demo/Demo"));
const AddExpenses = React.lazy(() =>
  import("../pages/PropertiesPages/AddExpenses")
);
const ViewExpenses = React.lazy(() =>
  import("../pages/PropertiesPages/ViewExpenses")
);
const AddIncome = React.lazy(() =>
  import("../pages/PropertiesPages/AddIncome")
);
const AddDocument = React.lazy(() =>
  import("../pages/PropertiesPages/AddDocument")
);
const ViewDocument = React.lazy(() =>
  import("../pages/PropertiesPages/ViewDocument")
);
const SummaryView = React.lazy(() =>
  import("../pages/ReportPages/SummaryView")
);
const PlSummaryView = React.lazy(() =>
  import("../pages/ReportPages/PlSummaryView")
);
const MonthlyView = React.lazy(() =>
  import("../pages/ReportPages/MonthlyView")
);
const FinancialPosition = React.lazy(() =>
  import("../pages/ReportPages/FinancialPosition")
);
const CapitalGrowth = React.lazy(() =>
  import("../pages/ReportPages/CapitalGrowth")
);
const InvesmentProjections = React.lazy(() =>
  import("../pages/ReportPages/InvesmentProjections")
);
const AboutUs = React.lazy(() => import("../pages/HomePages/AboutUs"));
const ProgressPage = React.lazy(() =>
  import("../components/LoadingPage/ProgressWithContent")
);
const BulkUploadIncome = React.lazy(() =>
  import("../pages/PropertiesPages/BulkUpload/Income/index")
);
const BulkUploadExpense = React.lazy(() => import("../pages/PropertiesPages/BulkUpload/Expense/index"));

// const Login = React.lazy(() => import('../pages/Login/login'));
// const Signup =React.lazy(()=>import('../pages/sign up/signup'))

const routes = [
  {
    path: "/",
    exact: false,
    element: Home,
    isAuthenticated: false,
    isCommon: true,
  },
  {
    path: "/login",
    exact: false,
    element: Login,
    isAuthenticated: false,
    // isCommon: true,
  },
  {
    path: "/public-capital-growth",
    exact: false,
    element: PublicCapitalGrowth,
    isAuthenticated: false,
    isCommon: true,
    // isCommon: true,PublicCapitalGrowth
  },
  {
    path: "/public-loan-repayment-calculator",
    exact: false,
    element: PublicLoanRepaymentCalculator,
    isAuthenticated: false,
    isCommon: true,
    // isCommon: true,PublicCapitalGrowth
  },
  {
    path: "/public-refinance-calculator",
    exact: false,
    element: PublicRefinanceCalculator,
    isAuthenticated: false,
    isCommon: true,
    // isCommon: true,PublicCapitalGrowth
  },
  {
    path: "/register",
    exact: false,
    element: Signup,
    isAuthenticated: false,
  },
  {
    path: "/contact",
    exact: false,
    element: Contact,
    isAuthenticated: false,
    isCommon: true,
  },
  {
    path: "/testimonials",
    exact: false,
    element: Testimonials,
    isAuthenticated: false,
    // isCommon: true,
  },
  {
    path: "/our-story",
    exact: false,
    element: About,
    isAuthenticated: true,
    isCommon: true,
  },
  {
    path: "/resources",
    exact: false,
    element: Resources,
    isAuthenticated: true,
    isCommon: true,
  },
  {
    path: "/calculators",
    exact: false,
    element: Calculator,
    isAuthenticated: true,
    isCommon: true,
  },

  // {
  //   path: "/add-reports/:row",
  //   exact: false,
  //   element: AddReports,
  //   isAuthenticated: true,
  //   // isCommon: true,
  // },
  {
    path: "/sidebar",
    exact: false,
    element: SideBarWithTopBar,
    isAuthenticated: false,
    // isCommon: true,
  },
  {
    path: "/profile-creations",
    exact: false,
    element: ProfileCreations,
    isAuthenticated: true,
    // isCommon: true,
  },
  {
    path: "/features",
    exact: false,
    element: Features,
    isAuthenticated: true,
    isCommon: true,
  },
  {
    path: "/rental-accounting",
    exact: false,
    element: RentalAccounting,
    isAuthenticated: true,
    isCommon: true,
  },
  {
    path: "/faq-s",
    exact: false,
    element: Faq,
    isAuthenticated: true,
    isCommon: true,
  },
  {
    path: "/forgot-password",
    exact: false,
    element: ForgetPassword,
    isAuthenticated: false,
    isCommon: true,
  },
  {
    path: "/Reset-password/:forgotPasswordToken",
    exact: false,
    element: ResetPassword,
    isAuthenticated: false,
    isCommon: true,
  },
  {
    path: "/add-property",
    exact: false,
    element: AddProperty,
    isAuthenticated: true,
    // isCommon: true,
  },
  {
    path: "/add-owner-occupied-property",
    exact: false,
    element: AddSelfProperty,
    isAuthenticated: true,
    // isCommon: true,
  },
  {
    path: "/edit-owner-occupied-property/:propertyId",
    exact: false,
    element: AddSelfProperty,
    isAuthenticated: true,
    // isCommon: true,
  },
  {
    path: "/edit-property/:propertyId",
    exact: false,
    element: AddProperty,
    isAuthenticated: true,
    // isCommon: true,
  },

  {
    path: "/property/:propertyId",
    exact: false,
    element: PropertyDetails,
    isAuthenticated: true,
    // isCommon: true,
  },
  {
    path: "/property/:propertyId/bulk-upload/expense",
    exact: false,
    element: BulkUploadExpense,
    isAuthenticated: true,
  },
  {
    path: "/property/:propertyId/bulk-upload/income",
    exact: false,
    element: BulkUploadIncome,
    isAuthenticated: true,
  },
  {
    path: "/dashboard",
    exact: false,
    element: Dashboard,
    isAuthenticated: true,
    // isCommon: true,
  },
  {
    path: "/help",
    exact: false,
    element: Help,
    isAuthenticated: true,
    // isCommon: true,
  },
  {
    path: "/data-usage",
    exact: false,
    element: DataUsage,
    isAuthenticated: true,
    // isCommon: true,
  },

  {
    path: "/reports",
    exact: false,
    element: Reports,
    isAuthenticated: true,
    // isCommon: true,
  },
  {
    path: "/documents",
    exact: false,
    element: Documents,
    isAuthenticated: true,
    // isCommon: true,
  },
  {
    path: "/documents/:propertyId",
    exact: false,
    element: ViewDocuments,
    isAuthenticated: true,
    // isCommon: true,
  },
  {
    path: "/documents/:propertyId/:documentType",
    exact: false,
    element: Statement,
    isAuthenticated: true,
    // isCommon: true,
  },


  {
    path: "/marketplace",
    exact: false,
    element: Marketplace,
    isAuthenticated: true,
    // isCommon: true,
  },
  {
    path: "/market-search-result",
    exact: false,
    element: MarketSearchResults,
    isAuthenticated: true,
    // isCommon: true,
  },
  {
    path: "/settings",
    exact: false,
    element: Settings,
    isAuthenticated: true,
    // isCommon: true,
  },
  {
    path: "/income-and-expense-breakdown-statement",
    exact: false,
    element: BreakdownStatement,
    isAuthenticated: true,
    // isCommon: true,
  },
  {
    path: "/EOFY-statement",
    exact: false,
    element: EofyView,
    isAuthenticated: true,
    // isCommon: true,
  },
  {
    path: "/summary-view",
    exact: false,
    element: SummaryView,
    isAuthenticated: true,
    // isCommon: true
  },
  {
    path: "/capital-growth-calculator",
    exact: false,
    element: CapitalGrowthCalculator,
    isAuthenticated: true,
    // isCommon: true
  },
  {
    path: "/rental-yield",
    exact: false,
    element: RentalYieldView,
    isAuthenticated: true,
    // isCommon: true
  },
  {
    path: "/profit-and-loss-summary",
    exact: false,
    element: PlSummaryView,
    isAuthenticated: true,
    // isCommon: true
  },
  {
    path: "/monthly-view",
    exact: false,
    element: MonthlyView,
    isAuthenticated: true,
    // isCommon: true
  },
  {
    path: "/financial-position-assets-and-liabilities-statement",
    exact: false,
    element: FinancialPosition,
    isAuthenticated: true,
    // isCommon: true
  },
  {
    path: "/capital-growth",
    exact: false,
    element: CapitalGrowth,
    isAuthenticated: true,
    // isCommon: true
  },
  {
    path: "/property",
    exact: false,
    element: Property,
    isAuthenticated: true,
    // isCommon: true,
  },
  {
    path: "/about",
    exact: false,
    element: About,
    isAuthenticated: true,
    isCommon: true,
  },
  {
    path: "/privacy",
    exact: false,
    element: PrivacyPolicy,
    isAuthenticated: true,
    isCommon: true,
  },
  {
    path: "/saasagreement",
    exact: false,
    element: SaasAgreement,
    isAuthenticated: true,
    isCommon: true,
  },
  {
    path: "/cookie-policy",
    exact: false,
    element: CookiePolicy,
    isAuthenticated: true,
    isCommon: true,
  },
  {
    path: "/terms-and-conditions",
    exact: false,
    element: TermsAndConditions,
    isAuthenticated: true,
    isCommon: true,
  },
  {
    path: "/account-activated",
    exact: false,
    element: YourAccountActivated,
    isAuthenticated: false,
    isCommon: true,
  },
  {
    path: "/add-expenses/:propertyId",
    exact: false,
    element: AddExpenses,
    isAuthenticated: true,
    // isCommon: true,
  },
  {
    path: "/add-expenses/:propertyId/update/:expenseId",
    exact: false,
    element: AddExpenses,
    isAuthenticated: true,
    // isCommon: true,
  },
  {
    path: "/view-expenses/:propertyId/view/:expenseId",
    exact: false,
    element: ViewExpenses,
    isAuthenticated: true,
    // isCommon: true,
  },
  {
    path: "/property/:propertyId/add-income",
    exact: false,
    element: AddIncome,
    isAuthenticated: true,
    // isCommon: true,
  },
  {
    path: "/property/:propertyId/update-income/:incomeId",
    exact: false,
    element: AddIncome,
    isAuthenticated: true,
    // isCommon: true,
  },
  {
    path: "/add-document/:propertyId",
    exact: false,
    element: AddDocument,
    isAuthenticated: true,
    // isCommon: true,
  },
  {
    path: "/add-document/:propertyId/:documentTypeId",
    exact: false,
    element: AddDocument,
    isAuthenticated: true,
    // isCommon: true,
  },
  {
    path: "/add-document/",
    exact: false,
    element: AddDocument,
    isAuthenticated: true,
    // isCommon: true,
  },
  {
    path: "/add-document/:propertyId/update/:documentTypeId/:documentId",
    exact: false,
    element: AddDocument,
    isAuthenticated: true,
    // isCommon: true,
  },
  {
    path: "/view-document/:propertyId/view/:documentTypeId/:documentId",
    exact: false,
    element: ViewDocument,
    isAuthenticated: true,
    // isCommon: true,
  },
  {
    path: "/request-a-feature",
    exact: false,
    element: FeatureRequest,
    isAuthenticated: true,
    isCommon: true,
  },
  {
    path: "/partner-affiliation",
    exact: false,
    element: PartnerAffiliation,
    isAuthenticated: true,
    isCommon: true,
  },

  {
    path: "/learn&support",
    exact: false,
    element: LearnAndSupport,
    isAuthenticated: false,
    // isCommon: true
  },
  {
    path: "/careers",
    exact: false,
    element: Careers,
    isAuthenticated: true,
    isCommon: true,
  },

  {
    path: "/pricing",
    exact: false,
    element: Pricing,
    isAuthenticated: true,
    isCommon: true,
  },
  {
    path: "/referral-program",
    exact: false,
    element: ReferralProgram,
    isAuthenticated: true,
    isCommon: true,
  },
  {
    path: "/demo",
    exact: false,
    element: Demo,
    isAuthenticated: true,
    isCommon: true,
  },
  {
    path: "/invesment-projections",
    exact: false,
    element: InvesmentProjections,
    isAuthenticated: true,
    // isCommon: true
  },
  {
    path: "/lvr-projections",
    exact: false,
    element: LVRProjections,
    isAuthenticated: true,
    // isCommon: true
  },
  {
    path: "/cashflow-projections",
    exact: false,
    element: CashFlowProjections,
    isAuthenticated: true,
    // isCommon: true
  },
  {
    path: "/refinance-calculator",
    exact: false,
    element: RefinanceCalculator,
    isAuthenticated: true,
    // isCommon: true
  },
  {
    path: "/loan-repayment-calculator",
    exact: false,
    element: LoanRepaymentCalculator,
    isAuthenticated: true,
    // isCommon: true
  },
  {
    path: "/under-progress",
    exact: false,
    element: ProgressPage,
    isAuthenticated: true,
    isCommon: true,
  },
];

export default routes;
