import { createSlice } from '@reduxjs/toolkit';
import { cookies } from '../../services/auth';
import { getProfile,updateProfile } from './profileActions';

const profileSlice = createSlice({
  name: 'profile',
  initialState: {
    loading: false,
    profiles:{
      _id: null,
      plans:[]
    },
  },
  reducers: {
  },
  extraReducers: (builder) => {
    // you can mutate state directly, since it is using immer behind the scenes
    builder
   
    .addCase(getProfile.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getProfile.fulfilled, (state, action) => {
      state.loading = false;
      if(action?.payload?.data){
        state.profiles = action?.payload?.data;
      }
    })
    .addCase(getProfile.rejected, (state, action) => {
      state.loading = false;
    })

   

    .addCase(updateProfile.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(updateProfile.fulfilled, (state, action) => {
      state.loading = false;
      if(action?.payload?.data){
        state.profile = action?.payload?.data;
      }
    })
    .addCase(updateProfile.rejected, (state, action) => {
      state.loading = false;
    })
  },
});

export default profileSlice.reducer;