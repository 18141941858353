import countries from "../constants/countries-states-cities.json";
import AWS from "aws-sdk";
import moment from "moment";
import { S3_BUCKET_NAME, AWS_S3_IDENTITY_POOL_ID, AWS_S3_REGION } from "../constants/portConstants";

// Use Cognito credentials instead of IAM credentials
AWS.config.region = AWS_S3_REGION; // Specify your AWS region
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
  IdentityPoolId: AWS_S3_IDENTITY_POOL_ID,
});

const s3 = new AWS.S3(); // Remove accessKeyId, secretAccessKey, and region from here

export async function Validate(schema, data) {
  let error = "";
  await schema.validate(data).catch(function (err) {
    error = {
      path: err.path,
      message: (err.errors || [])
        .map((e) => e[0].toUpperCase() + e.slice(1))
        .join(", "),
    };
  });
  return error;
}

export function getCountryName(countryId) {
  let country = countries.find((country) => country.id == countryId);
  return country
    ? country
    : {
        name: "N/A",
        emoji: "",
      };
}

export function toUsd(amount) {
  return (amount / 100).toFixed(2);
}

export function capitalizeFirstLetter(string) {
  if (!string) return "";
  return string.charAt(0).toUpperCase() + string.slice(1);
}
export function buildQueryParams(obj, parentKey = null) {
  const queryParams = [];

  for (const key in obj) {
    if (obj?.hasOwnProperty(key)) {
      const value = obj[key];
      const fullKey = parentKey ? `${parentKey}.${key}` : key;

      if (typeof value === "object" && value !== null) {
        // Recursively handle nested objects
        queryParams.push(buildQueryParams(value, fullKey));
      } else {
        // Handle non-object values
        queryParams.push(
          `${encodeURIComponent(fullKey)}=${encodeURIComponent(value)}`
        );
      }
    }
  }

  return queryParams.join("&");
}

export function formatSize(sizeInBytes) {
  const KB_THRESHOLD = 1024;
  const MB_THRESHOLD = 1024 * KB_THRESHOLD;
  const GB_THRESHOLD = 1024 * MB_THRESHOLD;

  if (sizeInBytes >= GB_THRESHOLD) {
    return (sizeInBytes / GB_THRESHOLD).toFixed(2) + " GB";
  } else if (sizeInBytes >= MB_THRESHOLD) {
    return (sizeInBytes / MB_THRESHOLD).toFixed(2) + " MB";
  } else if (sizeInBytes >= KB_THRESHOLD) {
    return (sizeInBytes / KB_THRESHOLD).toFixed(2) + " KB";
  } else {
    return sizeInBytes + " B";
  }
}

export function formatBytes(bytes, decimals = 2) {
  if (!+bytes) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}


export function handleFileDownload(fileUrl, fileName) {
  fetch(fileUrl)
    .then((response) => response.blob())
    .then((blob) => {
      // Create a temporary anchor element
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName); // Set the download attribute with the desired filename
      document.body.appendChild(link);
      link.click();

      // Cleanup
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);
    })
    .catch((error) => {
      console.error("Error downloading file:", error);
    });
}

export async function deleteS3File(filePath, bucketName = S3_BUCKET_NAME) {
  if (!filePath) return "";
  const params = {
    Bucket: bucketName,
    Key: filePath,
  };

  await s3.deleteObject(params).promise();
  return true;
}
